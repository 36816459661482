<template>
    <div class="campaigns">
        <div class="d-flex align-center home-header">
            <p class="ma-0 text-h5 primary--text font-weight-bold">{{$t("homeCampaignTitle")}}</p>

            <v-select
                v-model="usages"
                :label="$t('triggersFormUsageLabel')"
                :items="$t('triggersFormUsage')"
                item-text="value"
                item-value="key"
                hide-details
                small-chips
                chips
                class="rounded-lg custom-input custom-select ms-4"
                prepend-inner-icon="mdi-filter"
                outlined
                multiple
            >
                <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.value }}</span>
                    <span v-if="index > 0 && index < 3">, {{ item.value }}</span>
                    <span v-if="index === 3" class="grey--text text-caption">
                      , (+{{ usages.length - 3 }} {{$tc('other', usages.length - 3)}})
                    </span>
                </template>
                <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="toggleUsage"
                        @mousedown.prevent
                    >
                        <v-list-item-action>
                            <v-icon :color="usages.length > 0 ? 'indigo darken-4' : ''">
                                {{ iconUsage }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t('triggersSelectAllUsage') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mb-2"></v-divider>
                </template>
            </v-select>

            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="$router.push({name: 'ManageCampaign'})" outlined icon><v-icon>mdi-plus</v-icon></v-btn> -->
        </div>

        <div ref="campaigns-wrapper" class="campaigns-wrapper" :style="{gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`}">
            <v-card width="100%" class="mx-2 pa-3 mt-6 rounded-t-xl rounded-b-0 d-flex flex-column  align-center justify-center campaign-card add-campaign-card" @click="$router.push({name: 'ManageCampaign'})">
                <v-btn color="primary" outlined icon x-large><v-icon large>mdi-plus</v-icon></v-btn>
                <p class="my-2 text-center text-h6 primary--text font-weight-bold">{{$t("homeAddCampaignLabel")}}</p>
            </v-card>
            <v-card v-for="campaign in campaignsToShow" :key="campaign.id" width="100%" class="mx-2 pa-3 mt-6 rounded-t-xl rounded-b-0 campaign-card">
                <div class="my-2 d-flex align-center">
                    <v-chip :color="campaign.active ? 'primary' : 'customGrey' " label small class="text-overline">{{campaign.active ? $t("homeCampaignActive"): $t("homeCampaignInactive")}}</v-chip>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({name: 'ManageCampaign', params: {'campaign': campaign}})" x-small icon >
                        <v-icon small>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-divider class="mx-2" vertical></v-divider>
                    <v-btn x-small icon @click="removeCamapign(campaign)">
                        <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
                <!-- <div class="my-2 d-flex align-center">
                    <v-avatar size="32" :color="campaign.active ? 'primary' : 'customGrey' "></v-avatar>
                    <v-spacer></v-spacer>
                    <v-chip :color="campaign.active ? 'primary' : 'customGrey' " label small class="text-overline">{{campaign.active ? $t("homeCampaignActive"): $t("homeCampaignInactive")}}</v-chip>
                </div> -->
                <v-card-title class="pa-0 text-h6 font-weight-bold">{{campaign.name}}</v-card-title>
                <v-card-text class="pa-0 tex-caption">
                    <span>{{$t("homeCampaignNbActivation", {nbActivation: campaign.nbActivation})}}</span>
                </v-card-text>

                <v-card-actions class="pa-0 d-flex align-start flex-column">
                    <v-btn class="ma-0" color="primary" @click="$router.push({name: 'StatsSmartEngage', params: {campaign: campaign}})" text small><v-icon class="mr-1" small>mdi-chart-bar</v-icon> <p class="ma-0">{{$t("homeCampaignStats")}}</p></v-btn>
                    <v-btn class="ma-0" color="primary" @click="changeIsActive(campaign)" text small><v-icon class="mr-1" small>{{ campaign.active ? 'mdi-cancel' : 'mdi-power'}}</v-icon> {{campaign.active ? $t("homeCampaignDisable"): $t("homeCampaignEnable")}}</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
const CampaignService = require("@/services/CampaignService.js")

export default {
    name: 'Campaigns',
    data: () => {
        return {
            campaigns: [],
            numberOfColumns: 0,
            usages: [],
        }
    },
    created() {
        this.updatePageInfo(this.$route.meta)
        this.getCampaigns()

        sessionStorage.removeItem("SMART_ENGAGE_CAMPAIGN")
    },
    mounted () {
        let wrapperWidth = this.$refs["campaigns-wrapper"].offsetWidth;
        this.numberOfColumns = parseInt(wrapperWidth / 300) - 1
    },
    computed:{
        iconUsage() {
            if (this.selectAllUsage) return 'mdi-close-box'
            if (this.selectSomeUsage) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectAllUsage() {
            return this.usages.length === this.$t('triggersFormUsage').length
        },
        selectSomeUsage() {
            return this.usages.length > 0 && !this.selectAllUsage
        },
        campaignsToShow(){
            let allUsages = this.$t('triggersFormUsage').map(usage => usage.key)
            if(this.usages.length === 0 || this.usages.length === allUsages.length){
                return this.campaigns
            }
            return this.campaigns.filter(campaign => allUsages.includes(campaign.campaignUsage))
        }
    },
    methods: {
        getCampaigns(){
            CampaignService.getAllCampaigns().then(({status, data}) => {
                if(status === 200 && this.isNotNull(data)){
                    this.campaigns = data
                }
            })
        },
        changeIsActive(campaign){
            if((campaign.active && window.confirm(this.$t("homeDisableCampaignConfirmation", {campaignName: campaign.name}))) || !campaign.active){
                CampaignService.update(campaign.id, campaign.name, !campaign.active).then(({status, data}) => {
                    if(status === 200 && !data.error){
                        this.getCampaigns()
                    }
                })
            }
        },
        removeCamapign(campaign){
            if (window.confirm(this.$t("homeDeleteCampaignConfirmation", {campaignName: campaign.name}))) {
                CampaignService.remove(campaign.id).then(({status, data}) => {
                    if(status === 200 && !data.error){
                        this.getCampaigns()
                    }
                })
            }
        },
        toggleUsage() {
            this.$nextTick(() => {
                if (this.selectAllUsage) {
                    this.usages = []
                } else {
                    let tabTriggers = this.$t('triggersFormUsage')
                    this.usages = []
                    for (let i = 0; i < tabTriggers.length; i++) {
                        this.usages.push(tabTriggers[i].key)
                    }
                }
            })
        },
    },
}
</script>
<style>
    .campaign-card{
        border-bottom: solid 2px var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
    .campaigns-wrapper{
        display: grid;
        grid-gap: 16px;
        justify-items: center;
    }
    .add-campaign-card{
        cursor: pointer;
    }
    .custom-select{
        flex: none !important;
        min-width: 150px !important;
    }
</style>
